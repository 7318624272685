<template>
    <div class="work-info"> 
        <div class="loading-box"  v-show="showLoading">
            <div class="content" >
                <div class="point1"></div>
                <div class="point2"></div>
                <div class="point3"></div>
            </div>
        </div>
        <div class="all-box-work"  v-show="!showLoading">
            <div class="info-all-main-box">
                <div class="info-box-s1">
                    <p class="pp1">{{infoObj.jiaBanRenName}}提出的加班举证申请</p>
                    <p v-show="infoObj.currentLink === 'end'" :class="['pp2',{'daishenpi':infoObj.checkState === '待审批'},{'tongguo':infoObj.checkState === '通过'},{'butongguo':infoObj.checkState === '不通过'},{'bohui':infoObj.checkState === '驳回'},{'jinxingzhong':infoObj.checkState === '进行中'}]">{{infoObj.checkState}}</p>
                </div>
                <div class="info-box-s2">
                    <div class="info-box-s2-main">
                        <p class="time-title">加班 {{infoObj.jiaBanShiChang}} 小时</p>
                        <div class="time-box">
                            <div class="left-time">
                                <span class="dian"></span>
                                <span class="line"></span>
                                <span class="dian"></span>
                            </div>
                            <div class="right-time">
                                <p class="time1">{{infoObj.jiaBanStartDate}}</p>
                                <p class="time2">{{infoObj.jiaBanEndDate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-box-s3">
                    <div class="detail-title">加班明细</div>
                    <div class="common-detial-title">加班说明</div>
                    <div class="shuoming-box">
                        <span>{{infoObj.jiaBanReason?infoObj.jiaBanReason:'暂无加班说明'}}</span>
                    </div>
                    <div class="common-detial-title" v-show="infoObj.guijitupianList && infoObj.guijitupianList.length > 0">打卡照片</div>
                    <div class="waibo-box waibo-box-daka" v-show="infoObj.guijitupianList && infoObj.guijitupianList.length > 0">
                        <div class="detial-img-box">
                            <div class="img-box" v-for="(item,index) in infoObj.guijitupianList" :key="index" @click="clickImg(item,index)">
                                <img :src="item" alt="" >
                            </div>
                        </div>
                    </div>
                    <div class="common-detial-title">备注</div>
                    <div class="shuoming-box">
                        <span>{{infoObj.workNode?infoObj.workNode:'暂无备注'}}</span>
                    </div>
                    <div class="common-detial-title">工作内容</div>
                    <div class="detial-img-box" v-show="infoObj.photoList !== null">
                        <div class="img-box" v-for="(item,index) in infoObj.photoList" :key="index" @click="clickFujian(item)">
                            <img :src="item.url" alt="" >
                            <div class="name-box"><span>{{item.name}}</span></div>
                        </div>
                    </div>
                    <div class="shuoming-box" v-show="infoObj.photoList === null">
                        <span>暂无工作内容信息</span>
                    </div>
                </div>
                <!-- <div class="liubai-box-s2" v-show="infoObj.currentLink === 'boss'"></div>
                <div class="lead-home"  v-show="infoObj.currentLink === 'boss'">
                    <div class="lis-top"><img class="img-must" src="../assets/imgs/img-must-write.png" alt="">审批意见</div>
                    <div class="lis-bom">
                        <div class="lis-bom-main">
                            <van-field v-model="message" autosize type="textarea" placeholder="请输入审批意见" @input="inputHandler"/>
                        </div>
                    </div>
                </div> -->
                <img class="img-zhuangtai" src="../assets/imgs/icon-tongguo.png" alt="" v-show="infoObj.checkState === '通过'">
                <img class="img-zhuangtai" src="../assets/imgs/icon-bohui.png" alt="" v-show="infoObj.checkState === '驳回'">
                <img class="img-zhuangtai" src="../assets/imgs/icon-daishenpin.png" alt="" v-show="infoObj.checkState === '待审批'">
                <img class="img-zhuangtai" src="../assets/imgs/icon-jinxingzhong.png" alt="" v-show="infoObj.checkState === '进行中'">
                <img class="img-zhuangtai" src="../assets/imgs/icon-butongguo.png" alt="" v-show="infoObj.checkState === '不通过'">
                <div class="liucheng-box">
                    <div class="liucheng-title">流程</div>
                    <div class="liucheng-main-box">
                        <div class="liucheng-main-lis" v-for="(item,index) in liuchengList" :key="index">
                            <div class="liucheng-lis-left">
                                <img :src="item.checkImage" alt="" class="img-touxiang" v-show="item.checkImage !== null && item.checkImage !== ''">
                                <img src="../assets/imgs/img-no-touxiang copy.png" alt="" class="img-touxiang" v-show="item.checkImage === null || item.checkImage === ''">
                                <div class="wai-box" :style="{background:`${item.bgc}`}">
                                    <img :src="item.imgUrl" alt="" class="img-last">
                                </div>
                                <div class="shuxian-box">
                                    <div class="shuxian-main"></div>
                                </div>
                            </div>
                            <div class="liucheng-lis-right">
                                <div class="right-top-box">
                                    <span>{{item.checkRole}}</span>
                                    <span>{{item.checkDate?item.checkDate.slice(0,16):''}}</span>
                                </div>
                                <div class="right-bom-box">{{item.checkName}} <span v-show="item.status !== ''">{{`（${item.status}）`}}</span> </div>
                                <div class="info-jujue" v-show="item.checkOpinion !== null">{{item.checkOpinion}}</div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
import Vue from 'vue';
import { Tab, Tabs,ImagePreview,Uploader,Field,Toast ,Dialog ,Search } from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(ImagePreview);
Vue.use(Field);
Vue.use(Toast );
Vue.use(Dialog  );
Vue.use(Search  );
import * as api from "../api"

import imgTianxie from "../assets/imgs/tianxie-img.png"
import imgSuccess from "../assets/imgs/img-duihao-last.png"
import imgLoading from "../assets/imgs/jinxgzhong-img.png"
import imgCuowu from "../assets/imgs/cuowu-img.png"

export default {
   data() {
    return {
        
        fileList:[],

        infoObj:{},
        id:'',
        fujianList:[],
        message:'',
        success:'',
        showDialog:false,
        showLoading:true,

        liuchengList:[],
    }
   },
   mounted () {
        this.id = this.$route.query.id ? this.$route.query.id : '202307051615000089039'
        // this.id = this.$route.query.id
        this.getData()
        api.getJiabanLiuchengApi(this.id).then(res=>{
            console.log(res.data,"aaa");
            this.liuchengList = res.data.data
            this.liuchengList.forEach(item=>{
                if(item.checkDate === null){
                    item.times = ''
                }else{
                    item.times = new Date(item.checkDate)
                    item.times = this.fillZero(item.times.getMonth()+1)+'.'+this.fillZero(item.times.getDate())+' '+this.fillZero(item.times.getHours())+":"+this.fillZero(item.times.getMinutes())
                }
                if(item.checkRole === '申请人' && item.checkStudent === '0'){
                    item.status = ''
                    item.imgUrl = imgTianxie
                    item.bgc = 'rgb(171,165,255)'
                }else if(item.checkRole === '申请人' && item.checkStudent === '1'){
                    item.status = ''
                    item.imgUrl = imgSuccess
                    item.bgc = '#0aa052'
                }else if(item.checkRole === '部门主管' && item.checkStudent === '0'){
                    item.status = '审批中'
                    item.imgUrl = imgLoading
                    item.bgc = 'rgb(255,146,0)'
                }else if(item.checkRole === '部门主管' && item.checkStudent === '1'){
                    item.status = '通过'
                    item.imgUrl = imgSuccess
                    item.bgc = '#0aa052'
                }else if(item.checkRole === '部门主管' && item.checkStudent === '2'){
                    item.status = '不通过'
                    item.imgUrl = imgCuowu
                    item.bgc = 'red'
                }else if(item.checkRole === '部门主管' && item.checkStudent === '3'){
                    item.status = '驳回'
                    item.imgUrl = imgCuowu
                    item.bgc = 'red'
                }
            })
        })
        
   },
   methods: {
        fillZero(str){
            var realNum;
            if(str < 10){
                realNum	= '0'+str;
            }else{
                realNum	= str;
            }
            return realNum;
        },
        getData(){
            this.showLoading = true
            api.getJiabanInfoApi(this.id).then(res=>{
                console.log(res.data.data,"aaa");
                if(res.data){
                    this.showLoading = false
                    this.infoObj = res.data.data
                    if(this.infoObj.photoList !== null){
                        this.infoObj.photoList.forEach(item=>{
                            if(item.name.indexOf('doc') > -1){
                                item.url = require('../assets/imgs/word-img.png')
                            }else if(item.name.indexOf('xls') > -1){
                                item.url = require('../assets/imgs/excel-img.png')
                            }else if(item.name.indexOf('pdf') > -1){
                                item.url = require('../assets/imgs/pdf-img.png')
                            }else if(item.name.indexOf('zip') > -1){
                                item.url = require('../assets/imgs/yasuobao-img.png')
                            }else if(item.name.indexOf('mp4') > -1){
                                item.url = require('../assets/imgs/video.png')
                            }else if(item.name.indexOf('ppt') > -1){
                                item.url = require('../assets/imgs/ppt-img.png')
                            }else if(item.name.indexOf('txt') > -1){
                                item.url = require('../assets/imgs/tet-img.png')
                            }else if(item.name.indexOf('bmp') > -1 || item.name.indexOf('gif') > -1 || item.name.indexOf('jpg') > -1 || item.name.indexOf('jpeg') > -1 || item.name.indexOf('png') > -1 ){
                                item.url = item.value
                            }else{
                                item.url = require('../assets/imgs/wenjian-imgss.png')
                            }
                        })
                    }else{
                        // this.infoObj.photoList = null
                    }
                }else{
                    this.showLoading = true
                }
                
            })
        },
        inputHandler(i){
            console.log(i);
            this.message = i.trim()
        },
        clickImg(item,index){   
            console.log(item,index);        
            ImagePreview({      
                images: this.infoObj.guijitupianList,     
                closeable: true,        
                startPosition: index,       
            });         
        },      

        afterRead(file,detail){
            console.log(file,"file")
            console.log(detail,'detail')
            let params = {
                file:file.file
            }
            
            api.getFujianApi(params).then(res=>{
                console.log(res,"res")
                if(res){
                    this.fujianList.push({name:file.file.name,value: 'https://yrkq.yurun.com/api' + res.data})
                    this.fujianList.forEach(item=>{
                        if(item.name.indexOf('doc') > -1){
                            item.url = require('../assets/imgs/word-img.png')
                        }else if(item.name.indexOf('xls') > -1){
                            item.url = require('../assets/imgs/excel-img.png')
                        }else if(item.name.indexOf('pdf') > -1){
                            item.url = require('../assets/imgs/pdf-img.png')
                        }else if(item.name.indexOf('zip') > -1){
                            item.url = require('../assets/imgs/yasuobao-img.png')
                        }else if(item.name.indexOf('mp4') > -1){
                            item.url = require('../assets/imgs/video.png')
                        }else if(item.name.indexOf('ppt') > -1){
                            item.url = require('../assets/imgs/ppt-img.png')
                        }else if(item.name.indexOf('txt') > -1){
                            item.url = require('../assets/imgs/tet-img.png')
                        }else if(item.name.indexOf('bmp') > -1 || item.name.indexOf('gif') > -1 || item.name.indexOf('jpg') > -1 || item.name.indexOf('jpeg') > -1 || item.name.indexOf('png') > -1 ){
                            item.url = item.value
                        }else{
                            item.url = require('../assets/imgs/wenjian-imgss.png')
                        }
                    })
                }else{
                    Toast("上传失败,请重新上传或联系管理员")
                    this.fujianList = []
                }
                
                console.log(this.fujianList);
            })
        },     
        uploadHandler(){    
            
        },
        clickFujian(item){
            console.log(item);
            window.open(item.value)
        },
        clickQuxiaoHandler(item){
            console.log(item);
            this.fujianList = this.fujianList.filter((items) => {
                return items.value != item.value
            });
            console.log(this.fujianList );
        },
        
        clicSubmit1(){
            console.log(this.message);
            if(this.message === ''){
                this.message = '通过'
                Dialog.confirm({
                    title: '提示',
                    message: `是否确认同意批准${this.infoObj.jiaBanRenName}的加班举证申请`,
                    beforeClose:this.beforeClose1,
                });
            }else{
                Dialog.confirm({
                    title: '提示',
                    message: `是否确认同意批准${this.infoObj.jiaBanRenName}的加班举证申请`,
                    beforeClose:this.beforeClose1,
                });
            }
        },
        clicSubmit2(){
            console.log(this.message);
            if(this.message === ''){
                this.message = '驳回'
                Dialog.confirm({
                    title: '提示',
                    message: `是否确认驳回${this.infoObj.jiaBanRenName}的加班举证申请`,
                    beforeClose:this.beforeClose2,
                });
            }else{
                Dialog.confirm({
                    title: '提示',
                    message: `是否确认驳回${this.infoObj.jiaBanRenName}的加班举证申请`,
                    beforeClose:this.beforeClose2,
                });
            }
        },
        clicSubmit3(){
            console.log(this.message);
            if(this.message === ''){
                this.message = '不通过'
                Dialog.confirm({
                    title: '提示',
                    message: `是否确认${this.infoObj.jiaBanRenName}的加班举证不通过 <br> <span style="color:red;">举证不通过本次加班将视为无效加班</span>`,
                    beforeClose:this.beforeClose3,
                });
            }else{
                Dialog.confirm({
                    title: '提示',
                    message: `是否确认${this.infoObj.jiaBanRenName}的加班举证不通过 <br> <span style="color:red;">举证不通过本次加班将视为无效加班</span>`,
                    beforeClose:this.beforeClose3,
                });
            }
        },
        beforeClose1(action, done){
            this.success = ''
            console.log(action);
            if(action === 'confirm'){
                let params = { 
                    id:this.infoObj.businessId,
                    workImageAnnex:[],
                    workNotes:'',
                    check:1,
                    checkOpinion:this.message
                }
                api.getJiabanSubmitApi(params).then(res=>{
                    console.log(res);
                    if(res.data.data === '操作成功'){
                        done()
                        this.success = 'success'
                        setTimeout(() => {
                            window.location.reload()
                        },1500)
                    }else{
                        done()
                        this.success = 'fail'
                    }
                })
            }else{
                done()
                this.message= ''
                this.success = ''
            }
        },
        beforeClose2(action, done){
            this.success = ''
            console.log(action);
            if(action === 'confirm'){
                let params = { 
                    id:this.infoObj.businessId,
                    workImageAnnex:[],
                    workNotes:'',
                    check:3,
                    checkOpinion:this.message
                }
                api.getJiabanSubmitApi(params).then(res=>{
                    console.log(res);
                    if(res.data.data === '操作成功'){
                        done()
                        this.success = 'success'
                        setTimeout(() => {
                            window.location.reload()
                        },1500)
                    }else{
                        done()
                        this.success = 'fail'
                    }
                })
            }else{
                done()
                this.message= ''
                this.success = ''
            }
        },
        beforeClose3(action, done){
            this.success = ''
            console.log(action);
            if(action === 'confirm'){
                let params = { 
                    id:this.infoObj.businessId,
                    workImageAnnex:[],
                    workNotes:'',
                    check:2,
                    checkOpinion:this.message
                }
                api.getJiabanSubmitApi(params).then(res=>{
                    console.log(res);
                    if(res.data.data === '操作成功'){
                        done()
                        this.success = 'success'
                        setTimeout(() => {
                            window.location.reload()
                        },1500)
                    }else{
                        done()
                        this.success = 'fail'
                    }
                })
            }else{
                done()
                this.message= ''
                this.success = ''
            }
        },
   }
}
</script>
<style lang='less' scoped>
.work-info{
    width: 100%;
    height: 100%;
    background-color: #f4f6fc;
    position: relative;
    .all-box-work{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .info-all-main-box{
        width: 100%;
        height: 100%;
        // padding-top: 10px;
        overflow: auto;
        .info-box-s1{
            width: 100%;
            background-color: #fff;
            margin-bottom: 10px;
            padding: 12px;
            .pp1{
                width: 100%;
                font-size: 16px;
                color: #323232;
                letter-spacing: 0.8px;
                display: flex;
                align-items: center;
                font-weight: 600;
            }
            .pp2{
                padding-top: 6px;
                width: 100%;
                font-size: 13px;
                color: #333;
                letter-spacing: 0.6px;
                display: flex;
                align-items: center;
            }
            .daishenpi{
                color: #4e6ef2;
            }
            .tongguo{
                color: rgb(41, 189, 100);
            }
            .butongguo{
                color: red;
            }
            .bohui{
                color: red;
            }
            .jinxingzhong{
                color: rgb(79,20,127);
            }
        }
        .info-box-s2{
            width: 100%;
            background-color: #fff;
            padding: 8px;
            .info-box-s2-main{
                width: 100%;
                height: 100%;
                background-color: rgb(242,241,246);
                border-radius: 6px;
                padding: 10px 15px;
                .time-title{
                    width: 100%;
                    font-size: 15px;
                    color: #000;
                    letter-spacing: 0.5px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                }
                .time-box{
                    width: 100%;
                    display: flex;
                    padding: 10px 0px;
                    padding-bottom: 6px;
                    .left-time{
                        width: 30px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 0px;
                        .dian{
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background-color: rgb(204,204,204);
                        }
                        .line{
                            width: 2px;
                            height: 18px;
                            background-color: rgb(204,204,204);
                            border-radius: 3px;
                            margin-top: 3px;
                            margin-bottom: 3px;
                        }
                    }
                    .right-time{
                        width: calc(100% - 30px);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .time1{
                            width: 100%;
                            font-size: 14px;
                            color: rgb(108,108,108);
                            letter-spacing: 0.5px;
                        }
                        .time2{
                            width: 100%;
                            font-size: 14px;
                            color: rgb(108,108,108);
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }
        .info-box-s3{
            width: 100%;
            background-color: #fff;
            padding: 10px;
            padding-top: 0px;
            .detail-title{
                width: 100%;
                font-size: 13px;
                background-color: rgb(244,244,244);
                color: rgb(103, 102, 102);
                letter-spacing: 0.7px;
                display: flex;
                align-items: center;
                padding:5px 10px;
                border-radius: 4px;
            }
        }
        .common-detial-title{
            width: 100%;
            font-size: 13px;
            color: rgb(126,126,126);
            letter-spacing: 0.7px;
            display: flex;
            align-items: center;
            padding:7px 10px;
            padding-left: 3px;
            border-radius: 4px;
            padding-top: 12px;
        }
        .shuoming-box{
            width: 100%;
            max-height: 78px;
            font-size: 14px;
            letter-spacing: 1px;
            overflow: auto;
            color: #000;
            padding: 5px 3px;
            padding-top: 3px;

        }
        
        .detial-img-box{
            width: 100%;
            white-space: nowrap;
            overflow-x: scroll;
            padding-top: 6px;
            .img-box{
                display: inline-block;
                width: 64px;
                margin-right: 8px;
                img{
                    width: 60px;
                    height: 50px;
                    border-radius: 5px;
                }
                .name-box{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap; //禁止换行
                    overflow: hidden;
                    text-overflow: ellipsis; 
                    margin-top: 4px;
                    span{
                        white-space: nowrap; //禁止换行
                        overflow: hidden;
                        text-overflow: ellipsis; 
                    }
                }
            }
        }
        .detial-img-box::-webkit-scrollbar{display:none}
        .waibo-box-daka{
                width: 100%;
                .img-box{
                    img{
                        height: 70px;
                    }
                }
                
            }
        .liubai-box-s2{
            width: 100%;
            height: 10px;
            background-color: #f4f6fc;
        }
        .lead-home{
            width: 100%;
            background-color: #fff;
            margin-bottom: 10px;
            padding-top: 5px;
            .lis-top{
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #333;
                letter-spacing: 1px;
                background-color: #fff;
                margin-bottom: 2px;
                padding: 4px 10px;
                .img-must{
                    width: 15px;
                    height: 15px;
                }
            }
            .lis-bom{
                width: 100%;
                background-color: #fff;
                padding: 10px;
                padding-top: 5px;
                .lis-bom-main{
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #eee;
                    font-size: 14px;
                    color: #74798c;
                    letter-spacing: 1.5px;
                    line-height: 23px;
                    border-radius: 4px;
                    /deep/ .van-field{
                        height: 100%;
                        padding: 0px!important;
                        font-size: 14px!important;
                        color: #74798c!important;
                        .van-field__control{
                            letter-spacing: 1.5px;
                        }
                    }
                    /deep/ .van-field__control{
                        max-height: 100px!important;
                    }
                    /deep/ .van-field__left-icon{
                        display: none;
                    }
                }
            }
        }
        .liubai-box{
            width: 100%;
            height: 54px;
        }
        .bom-submit{
            width: 100%;
            height: 54px;
            background-color: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 100;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 8px 8px 0px 0px;
            .submit-box{
                width: 30%;
                height: 30px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                letter-spacing: 3px;
                color: rgb(0,134,256);
                border-right: 1px solid #ccc;
            }
            .no-bor{
                border-right: none;
            }
        }
        .img-zhuangtai{
            position: fixed;
            width: 65px;
            height: 65px;
            right: 20px;
            top: 40px;
        }
        .liucheng-box{
            width: 100%;
            background-color: #fff;
            margin-bottom: 8px;
            padding-top: 5px;
            .liucheng-title{
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #333;
                letter-spacing: 1px;
                background-color: #fff;
                margin-bottom: 8px;
                padding: 4px 10px;
            }
            .liucheng-main-box{
                width: 100%;
                padding: 0px 20px;
                padding-bottom: 1px;
                .liucheng-main-lis{
                    width: 100%;
                    min-height: 70px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    .liucheng-lis-left{
                        width: 40px;
                        border-radius: 4px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        .img-touxiang{
                            width: 40px;
                            height: 40px;
                            border-radius: 4px;
                        }
                        .wai-box{
                            width: 15px;
                            height: 15px;
                            background-color: #0aa052;
                            position: absolute;
                            top: 28px;
                            left: 40px;
                            z-index: 2;
                            border-radius: 50%;
                            border: 2px solid #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .img-last{
                                width: 10px;
                                height: 10px;
                            }
                        }
                        .shuxian-box{
                            width: 100%;
                            height: calc(100% - 40px);
                            display: flex;
                            align-items: flex-end;
                            padding-top: 5px;
                            .shuxian-main{
                                width: 2px;
                                height: 100%;
                                background-color: rgb(226,226,226);
                                border-radius: 2px;
                                margin-left: 20.5px;
                            }
                        }
                    }
                    .liucheng-lis-right{
                        width: calc(100% - 60px);
                        height: 100%;
                        padding-left: 10px;
                        position: relative;
                        .right-top-box{
                            width: 100%;
                            height: 20px;
                            color:#999;
                            letter-spacing: 0.5px;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-right: 10px;
                        }
                        .right-bom-box{
                            width: 100%;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            color: #000;
                            font-size: 13px;
                        }
                        .info-jujue{
                            width: 100%;
                            color:#999;
                            letter-spacing: 0.5px;
                            font-size: 12px;
                            padding-top: 2px;
                        }
                    }
                }
                .liucheng-main-lis:last-child{
                    margin-bottom: 5px;
                    .shuxian-box{
                        display: none;
                    }
                }
                
            }
    }
    }
    .info-all-main-box::-webkit-scrollbar{display:none}
    .chengg-box{
        width: 100%;
        height: 100%;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .img-success{
            width: 180px;
            height: 180px;
        }
        .text-s{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            font-size: 16px;
            letter-spacing: 1.5px;
            color: #000;
            margin-top: 20px;
        }

    }
}
.loading-box{
    width: 100%;
    height: 100%;
    .content{
        width: 150px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        position: absolute;
        top: 0px;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        div {
            width: 10px;
            height: 10px;
            background-color: blue;
            border-radius: 100%;
            display: inline-block;
            animation: action 1.5s infinite ease-in-out;
            animation-fill-mode: both;
            margin-right: 8px;
        }
        .point1 {
            animation-delay: -0.3s;
        }
        .point2 {
            animation-delay: -0.1s;
        }
    }
    @keyframes action {
        0%, 80%, 100% {
            transform: scale(0);
        }
        40% {
            transform: scale(1.0);
        }
    }
}
</style>